/* eslint-disable react/style-prop-object */
import React from "react";

type IProps = {
  className?: string;
};

export const IconSortHover: React.FC<IProps> = (props) => (
  <svg
    width="11"
    height="17"
    viewBox="0 0 11 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.45517 3.84528C5.38642 3.85559 5.32282 3.88653 5.27298 3.93465L2.52298 6.57465C2.45337 6.63352 2.41127 6.71817 2.40654 6.80883C2.40138 6.89949 2.43404 6.98844 2.49634 7.05418C2.55908 7.12036 2.6463 7.15731 2.73697 7.15688C2.82806 7.15688 2.91443 7.11907 2.97673 7.05247L5.49991 4.62906L8.0231 7.05247C8.0854 7.11907 8.17177 7.15688 8.26286 7.15688C8.35353 7.15731 8.44075 7.12036 8.50349 7.05418C8.56579 6.98844 8.59845 6.8995 8.59329 6.80883C8.58857 6.71817 8.54646 6.63351 8.47685 6.57465L5.72685 3.93509C5.65466 3.86548 5.55454 3.83283 5.45528 3.84572L5.45517 3.84528Z"
      fill="currentcolor"
    />
    <path
      d="M5.45517 13.1547C5.38642 13.1444 5.32282 13.1135 5.27298 13.0653L2.52298 10.4253C2.45337 10.3665 2.41127 10.2818 2.40654 10.1912C2.40138 10.1005 2.43404 10.0116 2.49634 9.94582C2.55908 9.87964 2.6463 9.84269 2.73697 9.84312C2.82806 9.84312 2.91443 9.88093 2.97673 9.94753L5.49991 12.3709L8.0231 9.94753C8.0854 9.88093 8.17177 9.84312 8.26286 9.84312C8.35353 9.84269 8.44075 9.87964 8.50349 9.94582C8.56579 10.0116 8.59845 10.1005 8.59329 10.1912C8.58857 10.2818 8.54646 10.3665 8.47685 10.4253L5.72685 13.0649C5.65466 13.1345 5.55454 13.1672 5.45528 13.1543L5.45517 13.1547Z"
      fill="currentcolor"
    />
  </svg>
);

import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const TableCell = styled.div<{
  type?: "header";
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${{
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
  }};

  ${(props) =>
    props.type === "header" &&
    css`
      padding: 16px 0;
      color: ${props.theme.components.Table.Header.text.default};
    `}

  ${(props) =>
    props.type === undefined &&
    css`
      padding: 0;
      color: ${props.theme.components.Table.Row.text.default};
    `}
`;

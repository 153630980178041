import React, { ComponentProps, ComponentType } from "react";

type PropsComparator<C extends ComponentType> = (
  prevProps: Readonly<ComponentProps<C>>,
  nextProps: Readonly<ComponentProps<C>>,
) => boolean;

export function typedMemo<C extends ComponentType<never>>(
  Component: C,
  propsComparator?: PropsComparator<C>,
): C {
  return React.memo(Component, propsComparator) as never as C;
}

import styled from "@emotion/styled";
import { startCase } from "lodash";
import React from "react";

import { Table } from "~src/designSystem/tables/Table/components/Table";
import { nextSortOrder } from "~src/designSystem/tables/Table/utils/sort";
import { IUseListCheckable } from "~src/shared/lists/hooks/useListCheckable";
import { IListConfig, IListEmptyState, IListRow, IListRowData } from "~src/shared/lists/types";
import { IListDataSource } from "~src/shared/lists/types/data";
import { IListModel, IListModelColumnName } from "~src/shared/lists/types/models";
import { configKey, setSort, transformTableColumns } from "~src/shared/lists/utils/config";

type IProps<M extends IListModel> = {
  data: IListDataSource<M>;
  selectedIndex?: number;
  setSelectedIndex?: (i: number) => void;
  onRowClick?: (row: IListRow<M>) => void;
  setConfig: (config: IListConfig<M>) => void;
  checkable?: IUseListCheckable;
  leftPadding?: number;
  emptyState?: IListEmptyState;

  /**
   * The title for the table; displayed when there are no rows.
   * Optional; defaults to `startCase(config.model)`.
   */
  title?: string;

  /* If provided, disable the GOAT Table's sort modification feature. Default false.  */
  disableSort?: boolean;
};

export const List = <M extends IListModel>(props: IProps<M>): React.ReactElement => {
  const {
    data,
    selectedIndex,
    setSelectedIndex,
    setConfig,
    onRowClick,
    checkable,
    leftPadding = 0,
    disableSort = false,
    title = startCase(props.data.config.model),
    emptyState,
  } = props;
  const { config } = data;

  const columns = transformTableColumns(config);

  return (
    <GOATWrapper>
      <Table
        title={title}
        key={configKey(config)}
        columns={columns}
        data={{
          ...data,
          // This is a light wrapper to resolve the return type difference between loadRows for list and goat.
          loadRows: async (args) => {
            data.loadRows(args);
          },
        }}
        selectedRowIndex={selectedIndex}
        updateRowDataByIndex={(index: number, newRowData: unknown) => {
          // To avoid coupling lists tightly with GOAT, we enforce type safety above the
          // GOAT boundary.
          data.setRowDataByIndex(index, newRowData as IListRowData<M>);
        }}
        onColumnClick={(column) => {
          if (disableSort) {
            return;
          }

          const newConfig = setSort(
            config,
            column.rowKey as IListModelColumnName<M>,
            nextSortOrder(column.sortOrder),
          ) as IListConfig<M>;

          setConfig(newConfig);
        }}
        onRowClick={
          // To avoid the hover state on rows that are not clickable
          // only pass the onRowClick when it's defined.
          onRowClick === undefined
            ? undefined
            : (row) => {
                setSelectedIndex?.(row.index);
                onRowClick(row as IListRow<M>);
              }
        }
        leftPadding={leftPadding}
        checkable={checkable}
        disableSort={disableSort}
        emptyState={emptyState}
      />
    </GOATWrapper>
  );
};

const GOATWrapper = styled.div`
  flex-grow: 1;
  overflow-x: scroll;
`;

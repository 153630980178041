/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { PropsWithChildren } from "react";

type IProps = PropsWithChildren<{
  image: React.ReactNode;
}>;

export const EmptyStateWrapper: React.FC<IProps> = ({ children, image }) => {
  return (
    <Wrapper>
      <div
        css={css`
          width: 181px;
          height: 135px;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        {image}
      </div>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 318px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;

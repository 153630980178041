/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import dynamic from "next/dynamic";
import React from "react";

import { EmptyStateWrapper } from "~src/designSystem/atoms/EmptyStateWrapper";
import { OldHeadingUseTextInstead } from "~src/designSystem/deprecated/Heading";
import { OldParagraphUseTextInstead } from "~src/designSystem/deprecated/Paragraph";
import { IListEmptyState } from "~src/shared/lists/types";
import { IPipeTheme } from "~src/shared/theme/darkVariant";

const EmptyChart = dynamic(() =>
  import("~src/shared/animations/EmptyStateIcon").then((x) => x.EmptyStateIcon),
);

export const EmptyState: React.FC<IListEmptyState> = (props: IListEmptyState) => {
  const { title, description } = props;

  return (
    <EmptyStateWrapper image={<EmptyChart />}>
      <OldHeadingUseTextInstead
        css={css`
          font-size: 15px;
          font-weight: 500;
        `}
      >
        {title}
      </OldHeadingUseTextInstead>
      <OldParagraphUseTextInstead
        css={(theme: IPipeTheme) => css`
          color: ${theme.components.Table.EmptyState.text};
        `}
      >
        {description ?? "There's nothing in this list."}
      </OldParagraphUseTextInstead>
    </EmptyStateWrapper>
  );
};

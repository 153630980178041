import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import ContentLoader from "react-content-loader";

export interface IRectangularLoaderProps {
  borderRadius?: number;
  width: number;
  height: number;
  className?: string;
}

/**
 * Loader shaped like a rectangle.
 */
export const RectangularLoader: React.FC<IRectangularLoaderProps> = ({
  width,
  height,
  className,
  borderRadius = Math.min(Math.floor(height * 0.3), 8),
}) => {
  const theme = useTheme();
  return (
    <Wrapper height={height} width={width} className={className}>
      <ContentLoader
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        foregroundColor={theme.components.Loader.Gradient.foreground}
        backgroundColor={theme.components.Loader.Gradient.background}
      >
        <rect
          x="0"
          y="0"
          rx={borderRadius}
          ry={borderRadius}
          width={width.toString()}
          height={height.toString()}
        />
      </ContentLoader>
    </Wrapper>
  );
};

const Wrapper = React.memo(styled.div<{ height: number; width: number }>`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  line-height: 1em;
`);

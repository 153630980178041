import { DocumentNode, TypedDocumentNode } from "@apollo/client";

import { graphqlTag } from "~src/shared/helpers/graphqlTag";

import { IListConfig, IListRow } from "../types";
import { getListModelConfig, IListModel } from "../types/models";
import { IFilterOp } from "../types/operators";
import { IDataType } from "../types/types";

export type IQueryGraphQL<M extends IListModel> = (
  query: TypedDocumentNode<readonly IListRow<M>[]>,
) => Promise<IListRow<M>[]>;

enum QueryType {
  count = "count",
  fetch = "fetch",
}

const toGQLType = (dt: IDataType, isParamAnArray: boolean): string => {
  const dtString = (() => {
    switch (dt) {
      case IDataType.boolean:
        return `Boolean!`;
      case IDataType.numeric:
        // TODO(usmanm): fix for floats?
        return `Int!`;
      case IDataType.text:
        return `String!`;
      case IDataType.timestamp:
        return `timestamptz!`;
      default:
        throw new Error("unsupported data type: " + dt);
    }
  })();

  return isParamAnArray ? `[${dtString}]!` : dtString;
};

const buildFilterFragments = <M extends IListModel>(config: IListConfig<M>): [string, string] => {
  const model = getListModelConfig(config.model);

  const whereFragments: string[] = [];
  const placeholders: string[] = [];

  config.filters.forEach((f, pIdx) => {
    const metadata = model.columns[f.column];
    let clause;
    let isParamAnArray = false;

    switch (f.operator) {
      case IFilterOp.after:
        clause = `${f.column}: { _gt: $p${pIdx} }`;
        break;
      case IFilterOp.before:
        clause = `${f.column}: { _lt: $p${pIdx} }`;
        break;
      case IFilterOp.eq:
        clause = `${f.column}: { _eq: $p${pIdx} }`;
        break;
      case IFilterOp.gt:
        clause = `${f.column}: { _gt: $p${pIdx} }`;
        break;
      case IFilterOp.gte:
        clause = `${f.column}: { _gte: $p${pIdx} }`;
        break;
      case IFilterOp.in:
        clause = `${f.column}: { _in: $p${pIdx} }`;
        isParamAnArray = true;
        break;
      case IFilterOp.is_false:
        clause = `${f.column}: { _eq: false }`;
        break;
      case IFilterOp.is_true:
        clause = `${f.column}: { _eq: true }`;
        break;
      case IFilterOp.is_missing:
        clause = `${f.column}: { _is_null: true }`;
        break;
      case IFilterOp.lt:
        clause = `${f.column}: { _lt: $p${pIdx} }`;
        break;
      case IFilterOp.lte:
        clause = `${f.column}: { _lte: $p${pIdx} }`;
        break;
      case IFilterOp.neq:
        clause = `${f.column}: { _neq: $p${pIdx} }`;
        break;
      case IFilterOp.nin:
        clause = `${f.column}: { _nin: $p${pIdx} }`;
        isParamAnArray = true;
        break;
      default:
        throw new Error("unsupported filter op: " + f.operator);
    }

    if (metadata.enriched_via !== undefined) {
      clause = `${metadata.enriched_via}: { ${clause} }`;
    }

    whereFragments.push(`{ ${clause} }`);
    if (f.value !== undefined) {
      placeholders.push(`$p${pIdx}: ${toGQLType(metadata.data_type, isParamAnArray)}`);
    }
  });

  return [
    placeholders.join(", "),
    `_and: [
      ${whereFragments.join(",\n")}
    ]`,
  ];
};

export const buildFetchQuery = <M extends IListModel>(config: IListConfig<M>): DocumentNode => {
  const model = getListModelConfig(config.model);

  let orderByFragment;
  if (config.sort !== undefined) {
    const { column } = config.sort;
    orderByFragment = `{ ${column}: ${config.sort.sortOrder} }`;

    // If the column is joined, then wrap the order by in the nested tables name.
    const metadata = model.columns[column];
    if (metadata.enriched_via !== undefined) {
      orderByFragment = `{ ${metadata.enriched_via}: ${orderByFragment} }`;
    }
  } else {
    orderByFragment = "{ public_id: asc }"; // ~time based sorting
  }

  const selectFragments: string[] = [];
  const dependentColumnNames = config.columns.flatMap(({ name }) => {
    const metadata = model.columns[name];
    return metadata.dependent_columns ?? [];
  });
  const columnNames = new Set(config.columns.map(({ name }) => name).concat(dependentColumnNames));
  columnNames.forEach((name) => {
    const metadata = model.columns[name];

    if (metadata.enriched_via !== undefined) {
      selectFragments.push(`${metadata.enriched_via} { ${name} }`);
    } else {
      selectFragments.push(`${name}`);
    }
  });

  const [placeholders, where] = buildFilterFragments(config);
  let args = "";
  if (placeholders.length > 0) {
    args = `, ${placeholders}`;
  }

  const qs = `
    query list_${config.model}_${QueryType.fetch}_query(
      $offset: Int!,
      $limit: Int!
      ${args}
    ) {
      ${config.model} (
        where: { ${where} }
        order_by: ${orderByFragment}
        offset: $offset
        limit: $limit
      ) {
        public_id
        ${selectFragments.join("\n")}
      }
    }
    `;
  return graphqlTag(qs);
};

export const buildCountQuery = <M extends IListModel>(config: IListConfig<M>): DocumentNode => {
  const [placeholders, where] = buildFilterFragments(config);
  let args = "";
  if (placeholders.length > 0) {
    args = `(${placeholders})`;
  }

  const qs = `
  query list_${config.model}_${QueryType.count}_query${args} {
    ${config.model}_aggregate (
      where: { ${where} }
    ) {
      aggregate {
        count
      }
    }
  }
  `;
  return graphqlTag(qs);
};

export const placeholderVariables = <M extends IListModel>(
  config: IListConfig<M>,
): Record<string, unknown> => {
  const placeholders: Record<string, unknown> = {};
  config.filters.forEach((f, pIdx) => {
    if (f.value !== undefined) {
      placeholders[`p${pIdx}`] = f.value;
    }
  });
  return placeholders;
};

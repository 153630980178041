import styled from "@emotion/styled";
import React from "react";

import { Tooltip } from "~src/designSystem/atoms/Tooltip";
import { IUseListCheckable } from "~src/shared/lists/hooks/useListCheckable";

import { IconSortArrow } from "../icons/IconSortArrow";
import { IconSortHover } from "../icons/IconSortHover";
import { ITableColumn } from "../types";
import { computeTableGrid } from "../utils/styling";
import { TableCell } from "./TableCell";

interface ITableHeaderTitleProps {
  column: ITableColumn;
}

const TableHeaderTitle = (props: ITableHeaderTitleProps): React.ReactElement => {
  const { column } = props;
  if (column.tooltip === undefined) {
    return <Bold>{column.title}</Bold>;
  }

  return (
    <Tooltip tooltipContent={column.tooltip}>
      <Bold>{column.title}</Bold>
    </Tooltip>
  );
};

interface IProps {
  columns: readonly ITableColumn[];
  onColumnClick?: (column: ITableColumn) => void;
  checkable?: IUseListCheckable;
  leftPadding: number;
  sidePadding?: number;
  disableSort: boolean;
}

export const TableHeader = (props: IProps): JSX.Element => {
  const { columns, onColumnClick, checkable, leftPadding, sidePadding, disableSort } = props;

  return (
    <Wrapper
      showCheckbox={checkable !== undefined}
      columns={columns}
      leftPadding={leftPadding}
      sidePadding={sidePadding}
    >
      {checkable !== undefined && (
        <div>
          {/* TODO(usmanm): add checkbox back when it's not buggy */}
          {/* <Checkbox
            id=""
            label=""
            onClick={checkable.toggleHeaderSelected}
            checked={checkable.isHeaderSelected}
          /> */}
        </div>
      )}
      {columns.map((column) => {
        return (
          <TableCell key={column.rowKey} type="header">
            <ContentWrapper onClick={() => onColumnClick?.(column)} disableSort={disableSort}>
              <TableHeaderTitle column={column} />
              {(() => {
                if (disableSort) {
                  return null;
                }
                if (column.sortOrder === undefined) {
                  return <IconSortHover className="sort-hover-icon" />;
                }
                return <IconSortArrow order={column.sortOrder} />;
              })()}
            </ContentWrapper>
          </TableCell>
        );
      })}
    </Wrapper>
  );
};

interface IWrapper {
  showCheckbox: boolean;
  columns: readonly ITableColumn[];
  leftPadding: number;
  sidePadding?: number;
}

// NB: This right padding is a big hack which fixes the misaligment of header titles
// in the prescence of a vertical scrollbar. The assumption is that most users have
// enough data per table that a vertical scrollbar will exist, so we fix it by throwing
// in some padding to cover up for the misalignment.
const Wrapper = styled.div<IWrapper>`
  user-select: none;
  ${(props) =>
    computeTableGrid(props.showCheckbox, props.columns, props.leftPadding, 6, props.sidePadding)};

  background-color: ${(props) => props.theme.components.Table.Header.background};
`;

const Bold = styled.span`
  font-weight: 600;
`;

const ContentWrapper = styled.div<{ disableSort: boolean }>`
  display: flex;
  align-items: center;
  height: 17px;
  white-space: nowrap;
  gap: 6px;

  color: ${(props) => props.theme.components.Table.Header.text.default};

  .sort-hover-icon {
    display: none;
  }

  ${({ disableSort, theme }) =>
    !disableSort &&
    `
    &:hover {
      cursor: pointer;
      color: ${theme.components.Table.Header.text.hover};
      .sort-hover-icon {
        color: ${theme.components.Table.Header.text.hover};
        display: block;
      }
    }
  `};
`;

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import * as React from "react";

import { TableCell } from "~src/designSystem/tables/Table/components/TableCell";
import { ITableColumn, ITableRow } from "~src/designSystem/tables/Table/types";

interface IProps {
  columns: readonly ITableColumn[];
  row: ITableRow;
  onClick?: (row: ITableRow) => void;
  updateRowData: (data: unknown) => void;
}

const RowFieldsInner = (props: IProps) => {
  const { columns, onClick, row, updateRowData } = props;
  return (
    <Fields
      onClick={() => {
        onClick?.(row);
      }}
    >
      {columns.map((column, i) => {
        const ColumnRenderer = column.renderer;
        return (
          <FieldWrapper
            clickable={onClick !== undefined}
            index={i}
            key={column.rowKey}
            alignRight={column.alignRight === true}
          >
            {/* <span /> is here to enable text-overflow: ellipsis */}
            <span>
              <ColumnRenderer
                row={row}
                value={(rowInner) => rowInner.data[column.rowKey]}
                // TODO: The signature for this isn't great--we can probably find a
                // better API later. Luckily this is only used by renderers and those
                // are fairly small in number.
                updateValue={(updateFunc) => {
                  updateRowData(updateFunc(column.rowKey, row.data));
                }}
              />
            </span>
          </FieldWrapper>
        );
      })}
    </Fields>
  );
};

export const RowFields = React.memo(RowFieldsInner, (prevProps, nextProps) => {
  return (
    prevProps.row === nextProps.row &&
    prevProps.onClick === nextProps.onClick &&
    prevProps.columns === nextProps.columns
  );
});

const Fields = styled.div`
  display: contents;
`;

type IFieldWrapperProps = {
  clickable: boolean;
  index: number;
  alignRight: boolean;
};

const FieldWrapper = styled(TableCell)<IFieldWrapperProps>`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;

  justify-content: ${(props) => props.alignRight && "flex-end"};

  ${{
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
  }}

  & > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
  }

  &:hover {
    & > span {
      position: absolute;
      text-overflow: unset;
      overflow-x: scroll;
      scrollbar-width: none;
      ::-webkit-scrollbar {
        display: none;
      }
      z-index: 100;
      padding-right: ${(props) => props.alignRight || "10px"};
    }
  }

  ${(props) =>
    props.clickable &&
    css`
      :hover {
        cursor: pointer;
      }
    `}
`;

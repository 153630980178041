/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useRef } from "react";
import { AutoSizer } from "react-virtualized/dist/commonjs/AutoSizer";
import { InfiniteLoader } from "react-virtualized/dist/commonjs/InfiniteLoader";
import { List } from "react-virtualized/dist/commonjs/List";

import { EmptyState } from "~src/designSystem/tables/Table/components/EmptyState";
import { TableHeader } from "~src/designSystem/tables/Table/components/TableHeader";
import { TableRow } from "~src/designSystem/tables/Table/components/TableRow";
import { TableRowLoading } from "~src/designSystem/tables/Table/components/TableRowLoading";
import { ITableColumn, ITableData, ITableRow } from "~src/designSystem/tables/Table/types";
import { computeTableGridMinWidth } from "~src/designSystem/tables/Table/utils/styling";
import { IUseListCheckable } from "~src/shared/lists/hooks/useListCheckable";
import { IListEmptyState } from "~src/shared/lists/types";

const minBatchSize = 5000;

export interface ITableProps {
  title: string;

  columns: readonly ITableColumn[];
  data: ITableData;
  checkable?: IUseListCheckable;
  leftPadding?: number;
  sidePadding?: number;

  /* If provided, disable the table's sort feature. Default false.  */
  disableSort?: boolean;

  // TODO
  selectedRowIndex?: number;
  onColumnClick?: (column: ITableColumn) => void;
  onRowClick?: (row: ITableRow) => void;
  emptyState?: IListEmptyState;

  updateRowDataByIndex?: (index: number, data: unknown) => void;

  className?: string;
}

/**
 * Table is not the greatest of all tables.
 */
export const Table = (props: ITableProps): JSX.Element => {
  const theme = useTheme();

  const {
    className,
    columns,
    data,
    checkable,
    leftPadding,
    sidePadding,
    selectedRowIndex,
    onColumnClick,
    onRowClick,
    title,
    disableSort = false,
    emptyState = {
      title: `No ${title.toLowerCase()}`,
    },
    updateRowDataByIndex = () => {},
  } = props;
  const { loading, count, loadRows, getRowByIndex } = data;

  // a ref to clear the infinite loader cache
  const infiniteLoaderRef = useRef<InfiniteLoader>(null);

  const minWidth = computeTableGridMinWidth(checkable !== undefined, columns);

  return (
    <TableContainer className={className} minWidth={minWidth}>
      <TableHeader
        disableSort={disableSort}
        columns={columns}
        onColumnClick={onColumnClick}
        checkable={checkable}
        leftPadding={leftPadding ?? 0}
        sidePadding={sidePadding}
      />
      <InfiniteLoader
        isRowLoaded={({ index }) => getRowByIndex(index) !== null}
        minimumBatchSize={minBatchSize}
        threshold={1000}
        loadMoreRows={loadRows}
        rowCount={count ?? undefined}
        // TODO:(benny) using ref due to infinite loader api. need to fix
        ref={infiniteLoaderRef}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer
            // In these components, we override the widths of AutoSizer and the Grid because they
            // aren't correct. React-Virtualized rounds the widths to the nearest pixel, and when
            // they round up, they cause scrollbars to flicker and the list to start spazzing out.
            // See https://github.com/bvaughn/react-virtualized/issues/1287.
            css={css`
              width: 100% !important;
            `}
          >
            {({ width, height }) => (
              <div
                css={css`
                  width: 100%;

                  .ReactVirtualized__Grid {
                    min-width: ${minWidth}px;
                    width: 100% !important;
                    :focus {
                      outline: none;
                    }
                  }
                `}
              >
                <List
                  ref={registerChild}
                  height={height - theme.components.Table.rowHeight}
                  noRowsRenderer={() => {
                    return loading ? <></> : <EmptyState {...emptyState} />;
                  }}
                  onRowsRendered={onRowsRendered}
                  rowCount={count ?? 0}
                  rowHeight={theme.components.Table.rowHeight}
                  overscanRowCount={20}
                  rowRenderer={({ key, style, index }) => {
                    const row = getRowByIndex(index);
                    return (
                      <div key={key} style={style}>
                        <TableRowWrapper className="table-row-wrapper">
                          {row === null ? (
                            <TableRowLoading
                              columns={columns}
                              showCheckbox={checkable !== undefined}
                              leftPadding={leftPadding ?? 0}
                              sidePadding={sidePadding}
                            />
                          ) : (
                            <TableRow
                              columns={columns}
                              isSelected={index === selectedRowIndex}
                              row={row}
                              checkable={checkable}
                              onClick={onRowClick}
                              leftPadding={leftPadding ?? 0}
                              sidePadding={sidePadding}
                              updateRowData={(newData: unknown) =>
                                updateRowDataByIndex(index, newData)
                              }
                            />
                          )}
                        </TableRowWrapper>
                      </div>
                    );
                  }}
                  width={Math.max(width, minWidth)}
                />
              </div>
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    </TableContainer>
  );
};

/* Exposed to allow custom styling of rows. */
export const TableRowWrapper = styled.div`
  height: 100%;
`;

const TableContainer = styled.div<{
  minWidth: number;
}>`
  display: block;
  min-width: ${(props) => props.minWidth}px;
  color: ${(props) => props.theme.components.Table.text};
  height: calc(100% - 6px);
  width: 100%;
`;

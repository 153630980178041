import { ITableSortOrder } from "../types";

export const nextSortOrder = (sortOrder?: ITableSortOrder): ITableSortOrder | undefined => {
  let newSortOrder: ITableSortOrder | undefined;
  switch (sortOrder) {
    case undefined: {
      newSortOrder = "asc";
      break;
    }
    case "asc": {
      newSortOrder = "desc";
      break;
    }
    case "desc": {
      newSortOrder = undefined;
      break;
    }
  }

  return newSortOrder;
};

import styled from "@emotion/styled";
import React from "react";

import { RectangularLoader } from "~src/designSystem/loading/RectangularLoader";
import { TableCell } from "~src/designSystem/tables/Table/components/TableCell";
import { ITableColumn } from "~src/designSystem/tables/Table/types";
import { computeTableGrid } from "~src/designSystem/tables/Table/utils/styling";

interface IProps {
  columns: readonly ITableColumn[];
  showCheckbox: boolean;
  leftPadding: number;
  sidePadding?: number;
}

const randomGradients = Array(10)
  .fill(null)
  .map(() => Math.random());

const TableRowLoadingInner = (props: IProps) => {
  const { showCheckbox, columns, leftPadding, sidePadding } = props;

  // TODO(igm): each column should have its own loading spinner
  return (
    <Wrapper
      showCheckbox={showCheckbox}
      columns={columns}
      leftPadding={leftPadding}
      sidePadding={sidePadding}
    >
      {showCheckbox && <div />}
      {columns.map((_, i) => (
        <TableCell key={i}>
          <RectangularLoader
            width={Math.min(
              (randomGradients[Math.floor(Math.random() * randomGradients.length)] ?? 1) * 100 + 25,
              95,
            )}
            height={12}
            borderRadius={6}
          />
        </TableCell>
      ))}
    </Wrapper>
  );
};

export const TableRowLoading = React.memo(TableRowLoadingInner);

const Wrapper = styled.div<IProps>`
  display: grid;
  align-items: center;
  width: 100%;
  height: 100%;
  ${(props) =>
    computeTableGrid(props.showCheckbox, props.columns, props.leftPadding, 0, props.sidePadding)}
`;

import { css, SerializedStyles } from "@emotion/react";
import { sum } from "lodash";

import { ITableColumn } from "../types";

const SIDE_PADDING = 16;
const COLUMN_GAP = 10;
const CHECKBOX_WIDTH = 34;
const COLUMN_WIDTH = 100;

export const computeTableGrid = (
  showCheckbox: boolean,
  columns: readonly ITableColumn[],
  leftPadding = 0,
  rightPadding = 0,
  sidePadding: number = SIDE_PADDING,
): SerializedStyles => {
  // TODO(igm): should set certain columns to be certain widths somehow rather than evenly spacing them.

  return css`
    display: grid;
    padding: 0 ${sidePadding + rightPadding}px 0 ${sidePadding + leftPadding}px;
    column-gap: ${COLUMN_GAP}px;
    grid-template-columns: ${showCheckbox ? "34px" : ""} ${columns
        .map((column) => column.width ?? `minmax(100px, 1fr)`)
        .join(" ")};
    min-width: ${computeTableGridMinWidth(showCheckbox, columns)}px;
  `;
};

export const computeTableGridMinWidth = (
  showCheckbox: boolean,
  columns: readonly ITableColumn[],
): number => {
  return (
    (showCheckbox ? CHECKBOX_WIDTH : 0) +
    sum(columns.map((c) => c.minWidth ?? COLUMN_WIDTH + COLUMN_GAP)) +
    SIDE_PADDING * 2
  );
};
